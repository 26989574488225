<template>
    <q-layout view="hHh lpR fFf">

        <q-page-container>
            <router-view></router-view>
        </q-page-container>

        <q-footer elevated class="bg-grey-8 text-white text-caption q-pa-sm">
          {{ year }} ® AREAPLAN - Tool Realizzato da Cataimm s.r.l. P.Iva 02981280361 - Tel. 335 8381584 - Email: info@areaplan.cloud
          <br />
          <a href="https://www.iubenda.com/privacy-policy/73789630" class="text-white" title="Privacy Policy" target="_blank">Privacy Policy</a> - <a href="https://www.iubenda.com/privacy-policy/73789630/cookie-policy" class="text-white" title="Cookie Policy" target="_blank">Cookie Policy</a> - <a href="/terms-and-conditions" class="text-white" target="_blank">Termini e le condizioni d’uso</a>
        </q-footer>

    </q-layout>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Site Layout',
  data () {
    return {
      year: moment().year()
    }
  }
}
</script>
